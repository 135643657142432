import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAcMZcP2qC9C0lpr96aeEfjOwMn8NPRsa4",
  authDomain: "thinkwayapp.firebaseapp.com",
  projectId: "thinkwayapp",
  storageBucket: "thinkwayapp.firebasestorage.app",
  messagingSenderId: "316514856746",
  appId: "1:316514856746:web:04a330586ceae8da267853",
  measurementId: "G-JE9NSLH2JN"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const db = initializeFirestore(app, {
  localCache: { kind: "persistent" }
});
export const realtimeDatabase = getDatabase(app);

export const auth = getAuth();
