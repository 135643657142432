import { addDoc, collection, Timestamp } from "@firebase/firestore";
import { db } from "../firebaseConfig";

export default class AbstractService<T> {
    private collection: string;

    constructor(collection: string) {
        this.collection = collection;
    }

    // async get(id: string): Promise<T> {
    //     return Promise.resolve(undefined);
    // }
    async save(entity: T) {
        const vendasCollection = collection(db, this.collection);     
        const docRef = await addDoc(vendasCollection, {
          ...entity,
          data: Timestamp.fromDate(new Date())
        });
        return {
          ...entity,
          id: docRef.id // Inclui o ID da venda recém-criada
        };
    }   
}