import React, { useState } from 'react';
import { Box, Typography, TextField, Grid, Paper, Button } from '@mui/material';
import Empresa from '../models/empresa';
import EmpresaService from '../services/empresaService';

const Premissas: React.FC = () => {
  const [empresa, setEmpresa] = useState<Empresa>(new Empresa());
  const empresaService = new EmpresaService();

  const handleChange = (field: keyof Empresa, value: any) => {
    setEmpresa((prev: Empresa) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = async () => {
    try {
      await empresaService.save(empresa);
      console.log("Empresa salva com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar empresa:", error);
    }
  };

  
  return (
    <Box sx={{ padding: 4, maxWidth: 800, margin: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        Informações básicas para o planejamento
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ padding: 2, backgroundColor: '#1976d2', color: '#fff' }}>
            <Typography>Nome da empresa</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={empresa.nome}
              onChange={(e) => handleChange('nome', e.target.value)}
              InputProps={{ style: { color: 'white' } }}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography>Ano início do planejamento</Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              value={empresa.anoInicioPlanejamento}
              onChange={(e) => handleChange('anoInicioPlanejamento', Number(e.target.value))}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography>Planejar para quantos anos?</Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              value={empresa.planejarQuantosAnos}
              onChange={(e) => handleChange('planejarQuantosAnos', Number(e.target.value))}
            />
          </Paper>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Typography variant="h6">Missão</Typography>
        <Paper sx={{ padding: 2, backgroundColor: '#1976d2', color: '#fff' }}>
          <Typography>Missão</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={empresa.missao}
            onChange={(e) => handleChange('missao', e.target.value)}
            InputProps={{ style: { color: 'white' } }}
          />
        </Paper>
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Visão</Typography>
        <Paper sx={{ padding: 2, backgroundColor: '#1976d2', color: '#fff' }}>
          <Typography>Visão</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={empresa.visao}
            onChange={(e) => handleChange('visao', e.target.value)}
            InputProps={{ style: { color: 'white' } }}
          />
        </Paper>
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Valores</Typography>
        <Paper sx={{ padding: 2, backgroundColor: '#1976d2', color: '#fff' }}>
          <Typography>Insira os valores abaixo (um valor por campo)</Typography>
          {empresa.valores.map((valor: any, index: any) => (
            <TextField
              key={index}
              fullWidth
              variant="outlined"
              value={valor}
              onChange={(e) => {
                const newValores = [...empresa.valores];
                handleChange('valores', newValores);
              }}
              sx={{ marginTop: 1, backgroundColor: 'white' }}
            />
          ))}
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginTop: 1 }}
            onClick={() => handleChange('valores', [...empresa.valores, ''])}
          >
            Adicionar Valor
          </Button>
        </Paper>
      </Box>

      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Box>
    </Box>
  );
};

export default Premissas;